export class TrackingApi {
    constructor(utils) {
        this.utils = utils;
    }

    sendEventRequest(payload) {
        this.track(payload, 'submitEvent', 'wato event tracking');
    }

    sendEventMergeRequest(payload, eventMergeId) {
        this.track(payload, 'submitEventMerge', 'wato event merge tracking', eventMergeId);
    }

    sendMergeRequest(payload) {
        this.track(payload, 'submitMerge', 'wato merge tracking');
    }

    trackOnNextPageImpression(payload) {
        this.track(payload, 'trackOnNextPageImpression', 'wato tracking event on next page impression');
    }

    canTrack(payload) {
        return payload !== undefined && window.otto?.tracking;
    }

    track(payload, method, logMessage, eventMergeId) {
        if (!this.canTrack(payload)){
            return;
        }

        if (eventMergeId !== undefined) {
            otto.tracking[method](eventMergeId, payload);
        } else {
            otto.tracking[method](payload);
        }

        this.utils.debugLog(logMessage, payload, eventMergeId);
    }
}
